<template>
  <section v-if="!isLoading">
    <div class="flex-column-center">
      <div class="main-wrapper">
        <div v-if="isPartnerTenant()">
          <div class="header-items-between">
            <quick-action-button
              :allowedFeatures="allowedFeatures"
              :isAdmin="isAdmin"
              :loggedUser="loggedUser"
              :focusAreas="focusAreas"
              :identities="identities"
              :audiences="audiences"
              :departments="departments"
              :careerAcademicInterests="careerAcademicInterests"
              :partner="partnerName"
            />
            <section id="main" v-if="hasFeedAccess" class="dashboard-nav-wrapper">
              <router-link to="/" class="dashboard-nav-pill active"
                ><i class="fa-solid fa-grid" style="margin-right: 4px"></i> Dashboard
              </router-link>
              <router-link to="/feed" class="dashboard-nav-pill"
                ><i class="fa-sharp fa-solid fa-bullhorn" style="margin-right: 4px"></i>
                News &amp; Updates
              </router-link>
            </section>
          </div>
          <dabi-admin-dashboard :analyticsData="analyticsData" :partner="partnerName" />
        </div>
        <div v-else>
          <div class="header-items-between">
            <quick-action-button
              :allowedFeatures="allowedFeatures"
              :isAdmin="isAdmin"
              :loggedUser="loggedUser"
              :focusAreas="focusAreas"
              :identities="identities"
              :audiences="audiences"
              :departments="departments"
              :careerAcademicInterests="careerAcademicInterests"
            />
            <section v-if="hasFeedAccess" class="dashboard-nav-wrapper" style="margin-bottom: 32px">
              <router-link to="/" class="dashboard-nav-pill active"
                ><i class="fa-solid fa-grid" style="margin-right: 4px"></i> Dashboard
              </router-link>
              <router-link to="/feed" class="dashboard-nav-pill"
                ><i class="fa-sharp fa-solid fa-bullhorn" style="margin-right: 4px"></i>
                Campus360
              </router-link>
            </section>
          </div>
          <admin-dashboard :analyticsData="analyticsData" :cardsData="cardsData" />
        </div>
      </div>
    </div>
  </section>
  <section v-else>
    <loading-spinner wrapperClass="wrapper" />
  </section>
</template>
<script lang="ts">
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import PartnerDashboardAnalyticChart from "./PartnerDashboardAnalyticChart.vue";
import DabiAdminDashboard from "../partner_tenant/DabiAdminDashboard.vue";
import { isPartnerTenant } from "../../utils/CustomUtils";
import { useAuthStore } from "../../stores/auth";
import { FEATURES, hasPermission } from "../../utils/PermissionUtils";
import DashboardService from "../services/DashboardService";
import { useCommonDataStore } from "../../stores/commonData";
import QuickActionButton from "./QuickActionButton.vue";

export default {
  components: {
    "admin-dashboard": PartnerDashboardAnalyticChart,
    DabiAdminDashboard,
    LoadingSpinner,
    QuickActionButton,
  },
  name: "dashboard-admin-index",
  props: ["isAdmin", "isPartnerAdmin", "isPartnerManager"],
  data() {
    return {
      hasFeedAccess: hasPermission(FEATURES.ACTIVITY_FEED),
      isLoading: false,
      authStore: useAuthStore(),
      commonDataStore: useCommonDataStore(),
      service: new DashboardService(),
      analyticsData: [],
      cardsData: [],
      allowedFeatures: null,
      partnerName: null,
      loggedUser: { id: null, profile_pic: null, fullname: null, department_name: null }
    };
  },
  computed: {
    focusAreas() {
      return this.commonDataStore.getSingleDataArray("focus_areas");
    },
    audiences() {
      return this.commonDataStore.getSingleDataArray("audiences");
    },
    identities() {
      return this.commonDataStore.getSingleDataArray("identities");
    },
    careerAcademicInterests() {
      return this.commonDataStore.getSingleDataArray("cae");
    },
    departments() {
      return this.commonDataStore.getSingleDataArray("departments");
    },
  },
  async created() {
    await this.getPreData();
  },
  methods: {
    isPartnerTenant,
    async getPreData() {
      this.isLoading = true;
      const userData = this.authStore.getUser;
      this.fullName = `${userData.user.first_name} ${userData.user.last_name}`;
      this.userId = userData.user.id;
      const res = await this.service.getDashboardAdminPreData();
      if (res) {
        this.analyticsData = res.analytics_data;
        this.cardsData = res.cards_data;
        this.loggedUser = res.loggedUser;
        this.welcomeVideo = res.welcome_video;
        this.welcomeVideo.show = !res.welcome_video_seen;
        this.allowedFeatures = res.allowed_features;
        this.partnerName = res.partner_name;
      }
      this.isLoading = false;
    },
  },
  beforeRouteEnter(to, from, next) {
    const authStore = useAuthStore();
    if (authStore.getIsAdmin || authStore.getIsPartnerAdmin || authStore.getIsPartnerManager) {
      next();
    } else {
      next("/dashboard");
    }
  },
};
</script>
<style lang="scss" scoped>
.header-items-between {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
@media screen and (max-width: 576px) {
  .header-items-between {
    flex-direction: column;
    gap: 16px;
  }
}
</style>
