<template>
  <form @submit.prevent="" id="eu-form" enctype="multipart/form-data">
    <div class="task-form">
      <div class="inup-form-element-container" style="margin-top: 24px">
        <input type="text" v-model="task.name" class="inup-input" maxlength="255" required="" />
        <div class="inup-form-label-container">
          <label for="name">Task Title <span>*</span></label>
        </div>
      </div>
      <div class="toolbar-wrapper">
        <Toolbar elementId="desc" @updateText="updateDescription"></Toolbar>
      </div>
      <div class="inup-form-element-container">
        <textarea
          name="description"
          cols="40"
          rows="6"
          v-model="task.description"
          id="desc"
          class="inup-textarea-input no-resize"
          placeholder=""
        />
        <div class="inup-form-label-container">
          <label for="desc">Task Description</label>
        </div>
      </div>
      <div class="flex-row-wrapper">
        <div
          class="multiselect-wrapper single-select"
          style="width: 100%; margin: 0; margin-bottom: 24px; position: relative; height: 50px"
        >
          <multiselect
            v-model="taskItem.related_model"
            :options="ITEM_TYPES"
            @input="changeTypeInput"
            :multiple="false"
            label="name"
            track-by="id"
            placeholder="Embed additional item"
            :close-on-select="true"
            :allow-empty="false"
          >
          </multiselect>
          <i class="fal fa-angle-down eu-select-arrow" style="top: -35px; z-index: 8; cursor: pointer; left: 93%"></i>
          <div class="inup-form-label-container">
            <label>Item Type</label>
          </div>
        </div>
        <div class="inup-form-element-container" style="width: 100%; margin-top: 0">
          <datetime
            type="date"
            class="datetime-theme-blues"
            v-model="task.due_date"
            input-class="inup-input"
            :format="{ year: 'numeric', month: 'long', day: 'numeric' }"
            :phrases="{ ok: 'Submit', cancel: 'Cancel' }"
            :min-datetime="new Date().toISOString()"
            :week-start="7"
            title="Due Date"
            use12-hour
          ></datetime>
          <div class="inup-form-label-container">
            <label>Due Date</label>
          </div>
          <!-- error -->
        </div>
      </div>
      <div
        class="multiselect-wrapper single-select"
        v-if="taskItem.related_model.id"
        style="width: 100%; margin: 0; margin-bottom: 24px; position: relative; height: 50px"
      >
        <multiselect
          v-model="taskItem.related_object_id"
          :options="items"
          :multiple="false"
          label="name"
          placeholder=""
          track-by="id"
          :close-on-select="true"
          :allow-empty="false"
        >
        </multiselect>
        <i class="fal fa-angle-down eu-select-arrow" style="top: -35px; z-index: 8; cursor: pointer; left: 93%"></i>
        <div class="inup-form-label-container">
          <label>{{ taskItem.related_model.name }}</label>
        </div>
      </div>
      <div v-if="taskItem.related_model.id == 'resource'" class="task-form-upload-resource-button">
        <resources-upload
          :filterData="filterData"
          :hasIcon="true"
          :isQuestResource="true"
          :questId="questId"
          @resourceUploaded="resourceUploaded"
          ></resources-upload>
      </div>
      <button
        aria-label="Finish Quest/Task creation process"
        class="iu-btn-solid title-btn"
        style="margin-top: 0"
        @click="submitTask"
      >
        Save
      </button>
      <button aria-label="Cancel Editing Task" class="iu-btn-light title-btn" @click="cancelTaskEdit()">Cancel</button>
    </div>
  </form>
</template>

<script>
import Multiselect from "vue-multiselect";
import { Datetime } from "vue-datetime";
import DropzoneComponent from "../../form/components/DropzoneComponent.vue";
import QuestService from "../services/QuestService";
import ResourcesUpload from "../../library/components/ResourcesUpload.vue";
import { useCommonDataStore } from "../../stores/commonData";
import Toolbar from "../../components/Toolbar.vue";

const ITEM_TYPES = [
  { id: "course", name: "Pathway Course" },
  { id: "resource", name: "Resource Library Item" },
  { id: "event", name: "Event" },
];
const ITEM_TYPES_MAP = {
  course: "Pathway Course",
  resource: "Resource Library Item",
  event: "Event",
};

export default {
  name: "task-form",
  components: { DropzoneComponent, Multiselect, Datetime, ResourcesUpload, Toolbar },
  props: ["task", "questId"],
  computed: {},
  data() {
    return {
      ITEM_TYPES: ITEM_TYPES,
      ITEM_TYPES_MAP: ITEM_TYPES_MAP,
      isDeleteModalActive: false,
      isHyperlinkModalActive: false,
      itemToDelete: { id: null, name: null },
      items: [],
      taskItem: { related_model: { id: null }, related_object_id: { id: null } },
      service: new QuestService(),
      isNotificationActive: false,
      notification: { msg: "", isError: false, icon: null },
      commonDataStore: useCommonDataStore(),
      filterData: {
        focus_areas: useCommonDataStore().getSingleDataArray('focus_areas'),
        departments: [],
        identities: useCommonDataStore().getSingleDataArray('identities'),
        audiences: useCommonDataStore().getSingleDataArray('audiences'),
      },
    };
  },
  async created() {
    if (this.task.items[0]) {
      const type = this.task.items[0].type;
      const name = this.task.items[0].item.name ? this.task.items[0].item.name : this.task.items[0].item.title;
      this.taskItem = {
        ...this.task.items[0],
        related_model: { id: type, name: ITEM_TYPES_MAP[type] },
        related_object_id: { id: this.task.items[0].item.id, name },
      };
      this.getTaskFilterData({ id: type });
    }
  },
  methods: {
    async changeTypeInput(itemType) {
      this.taskItem.related_object_id = { id: null };
      this.getTaskFilterData(itemType);
    },
    async getTaskFilterData(itemType) {
      const res = await this.service.getTaskFilterData({ item_type: itemType.id, quest_id: this.questId });
      if (res.items) {
        if (itemType.id === "event") {
          this.items = res.items.map((i) => {
            i.name = i.title;
            delete i.title;
            return i;
          });
        } else {
          this.items = res.items;
        }
      } else {
        this.showNotification({
          msg: "Server Error: Cannot fetch additional items",
          isError: true,
          icon: "fad fa-exclamation-circle",
        });
      }
    },
    validateForm() {
      if (!this.task.name) {
        this.showNotification({
          msg: "Error: You must fill in all required fields",
          isError: true,
          icon: "fad fa-exclamation-circle",
        });
        return false;
      }
      return true;
    },
    parseData() {
      const taskDTO = { ...this.task };
      if (!taskDTO.due_date) {
        delete taskDTO.due_date;
      } else {
        taskDTO.due_date = this.task.due_date.split("T")[0];
      }
      delete taskDTO.items;
      if (this.taskItem.related_model.id) {
        taskDTO.items_input = [];
        taskDTO.items_input[0] = {
          related_model: this.taskItem.related_model.id,
          related_object_id: this.taskItem.related_object_id.id,
        };
      }

      return taskDTO;
    },
    async submitTask() {
      const isValid = this.validateForm();
      const taskDTO = isValid && this.parseData();
      this.$emit("create-task", taskDTO);
    },
    cancelTaskEdit() {
      this.$emit("cancel-task-edit");
    },
    closeNotification() {
      this.$emit("close-notification");
    },
    showNotification(notification) {
      this.$emit("show-notification", notification);
    },
    resourceUploaded(uploadedResource) {
      this.items.push(uploadedResource);
      this.taskItem.related_object_id = uploadedResource;
    },
    updateDescription(updatedText) {
      this.task.description = updatedText;
    }
  },
};
</script>
<style lang="scss" scoped>
.task-form {
  border: 1px solid #d1d1d7;
  padding: 16px;
  border-radius: 8px;
}
@media only screen and (max-width: 777px) {
  .task-form-upload-resource-button {
    margin-bottom: 24px;
  } 
}
@media only screen and (min-width: 578px) {
  .autoW {
    width: auto;
  }
  .flex-row-wrapper {
    display: flex;
    justify-content: center;
    gap: 16px;
  }
}
</style>