<template>
    <div class="toolbar-container">
        <button
            class="toolbar-button"
            @click.prevent="handleBoldText"
            aria-label="Bold text"
          >
            <i class="fa-solid fa-bold"></i>
        </button>
        <button
            class="toolbar-button"
            @click.prevent="handleItalicText"
            aria-label="Italic text"
          >
            <i class="fa-solid fa-italic"></i>
        </button>
        <button
            class="toolbar-button"
            @click.prevent="openHyperlinkModal"
            aria-label="open hyperlink modal"
        >
            <i class="fa-solid fa-link"></i>
        </button>
        <HyperlinkModal
            :isHyperlinkModalActive="isHyperlinkModalActive"
            :initialHyperlinkText="preselectedText"
            @closeHyperlinkModal="closeHyperlinkModal"
            @appendHyperlinkToDescription="appendHyperlinkToDescription"
        >
        </HyperlinkModal>
    </div>
</template>
<script>
import HyperlinkModal from './specializedModals/HyperlinkModal.vue';
export default {
    name: "toolbar",
    components: {HyperlinkModal},
    props: ['elementId', 'preselectedRange'],
    computed: {
        element() {
            if (this.elementId) {
                return document.getElementById(this.elementId);
            }
            return null;
        },
    },
    data() {
        return {
            isHyperlinkModalActive: false,
            preselectedText: null,
            preselectedPositionEnd: null,
            preselectedPositionStart: null
        }
    },
    methods: {
        emitUpdatedText() {
            this.$emit('updateText', this.element.value);
        },
        handleItalicText() {
            const start = this.element.selectionStart;
            const end = this.element.selectionEnd;
            const selectedText = this.element.value.substring(start, end);
            if (selectedText) {
                this.element.value = this.element.value.substring(0, start) + "*" + selectedText + "*" + this.element.value.substring(end);
                this.element.focus();
                this.element.setSelectionRange(start + 1, end + 1);
            } else if (start) {
                this.element.value = this.element.value.substring(0, start) + "**" + this.element.value.substring(end);
                this.element.focus();
                this.element.setSelectionRange(start + 1, start + 1);
            } else {
                
                this.element.value += "**";
                this.element.focus();
                this.element.setSelectionRange(this.element.value.length - 1, this.element.value.length - 1);
            }
            this.emitUpdatedText();
        },
        handleBoldText() {
            const start = this.element.selectionStart;
            const end = this.element.selectionEnd;
            const selectedText = this.element.value.substring(start, end);
            if (selectedText) {
                this.element.value = this.element.value.substring(0, start) + "**" + selectedText + "**" + this.element.value.substring(end);
                this.element.focus();
                this.element.setSelectionRange(start + 2, end + 2);
            } else if (start) {
                this.element.value = this.element.value.substring(0, start) + "****" + this.element.value.substring(end);
                this.element.focus();
                this.element.setSelectionRange(start + 2, start + 2);
            } else {
                this.element.value += "****";
                this.element.focus();
                this.element.setSelectionRange(this.element.value.length - 2, this.element.value.length - 2);
            }
            this.emitUpdatedText();
        },
        closeHyperlinkModal() {
            this.isHyperlinkModalActive = false;
            this.preselectedText = null;
            this.preselectedPositionEnd = null;
            this.preselectedPositionStart = null;
        },
        appendHyperlinkToDescription(hyperlinkUrl, hyperlinkText) {
            const hyperlinkTextAppend = `!hyperlink![${hyperlinkUrl}][${hyperlinkText}]`;
                const selectedTextStart = this.preselectedPositionStart + hyperlinkTextAppend.length - hyperlinkText.length - 1;
                const selectedTextEnd = this.preselectedPositionStart + hyperlinkTextAppend.length - 1;
            if (this.preselectedText) {
                this.element.value = this.element.value.substring(0, this.preselectedPositionStart) + hyperlinkTextAppend + this.element.value.substring(this.preselectedPositionEnd);
                this.element.focus();
                this.element.setSelectionRange(selectedTextStart, selectedTextEnd);
            } else if (this.preselectedPositionStart) {
                this.element.value = this.element.value.substring(0, this.preselectedPositionStart) + hyperlinkTextAppend + this.element.value.substring(this.preselectedPositionEnd);
                this.element.focus();
                this.element.setSelectionRange(selectedTextEnd, selectedTextEnd);
            } else {
                this.element.value += hyperlinkTextAppend;
                this.element.focus();
                this.element.setSelectionRange(this.element.value.length - 1, this.element.value.length - 1);
            }
            this.closeHyperlinkModal();
            this.emitUpdatedText();
        },
        openHyperlinkModal() {
            this.preselectedPositionEnd = this.element.selectionEnd;
            this.preselectedPositionStart = this.element.selectionStart;
            this.preselectedText = this.element.value.substring(this.preselectedPositionStart, this.preselectedPositionEnd);
            this.isHyperlinkModalActive = true;
        },
    }
}
</script>
<style lang="scss">
.toolbar-wrapper {
  margin-bottom: 14px;
}
.toolbar-button {
  cursor: pointer;
  border: none;
  background: transparent;
  color: #b4b4b4;
  height: 24px;
  width: 24px;
  font-size: 18px;

  &:hover {
    color: #22a5a0;
  }
}
</style>