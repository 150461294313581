<template>
    <Modal :isActiveProp="isHyperlinkModalActive" @close="closeHyperlinkModal">
      <div class="iu-resources-delete-modal-content-container">
        <div class="iu-resources-delete-modal-text-section">Add hyperlink</div>
        <form class="iu-resources-delete-modal-file-details-section" @submit.prevent="appendHyperlinkToDescription(hyperlinkUrl, hyperlinkText)">
          <div class="inup-form-element-container" style="width: 100%">
            <input
              type="text"
              placeholder="Hyperlink url"
              v-model="hyperlinkUrl"
              id="hyperlinkUrl"
              class="inup-input"
              maxlength="255"
              :required=true
            />
            <div class="inup-form-label-container">
              <label for="name">Link</label>
            </div>
          </div>
          <div class="inup-form-element-container" style="width: 100%">
            <input
              type="text"
              placeholder="Hyperlink text"
              v-model="hyperlinkText"
              id="name"
              class="inup-input"
              maxlength="255"
              :required=true
            />
            <div class="inup-form-label-container">
              <label for="name">Text</label>
            </div>
          </div>
        </form>
        <div v-if="errorText" class="error-container">
          {{ errorText }}
        </div>
        <div class="iu-resources-delete-modal-actions-section">
          <button
            id="quest_delete"
            class="iu-resources-delete-modal-actions-button iu-main-btn small solid main"
            @click.prevent="appendHyperlinkToDescription(hyperlinkUrl, hyperlinkText)"
            aria-label="Add hyperlink to description"
          >
            Submit
          </button>
          <button
            class="iu-resources-delete-modal-actions-button iu-main-btn small outlined gray"
            @click.prevent="closeHyperlinkModal"
            aria-label="Close hyperlink modal modal"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
</template>
<script>
import Modal from '../../components/ModalExtended.vue';
import { isURLInvalid } from '../../utils/CustomUtils';

export default {
    name: "hyperlink-modal",
    components: {
        Modal
    },
    props: [
        "initialHyperlinkUrl",
        "initialHyperlinkText",
        "isHyperlinkModalActive"
    ],
    data() {
        return {
            hyperlinkUrl: "",
            hyperlinkText: "",
            errorText: null,
        }
    },
    created() {
        if (this.initialHyperlinkText) {
            this.hyperlinkText = this.initialHyperlinkText;
        } 
        if (this.initialHyperlinkUrl) {
            this.hyperlinkUrl = this.initialHyperlinkUrl; 
        }
    },
    watch: {
      initialHyperlinkText(newValue) {
        if (newValue) {
          this.hyperlinkText = newValue;
        }
      },
      initialHyperlinkUrl(newValue) {
        if (newValue) {
          this.hyperlinkUrl = newValue;
        }
      },
    },
    methods: {
        appendHyperlinkToDescription(hyperlinkUrl, hyperlinkText) {
            if (this.validateHyperlinkForm(hyperlinkUrl, hyperlinkText)) {
                this.$emit("appendHyperlinkToDescription", hyperlinkUrl, hyperlinkText);
                this.hyperlinkText = "";
                this.hyperlinkUrl = "";
            }
        },
        closeHyperlinkModal() {
          this.errorText = null;
            this.hyperlinkText = "";
            this.hyperlinkUrl = "";
            this.$emit("closeHyperlinkModal");
        },
        validateHyperlinkForm(hyperlinkUrl, hyperlinkText) {
            if (!hyperlinkUrl || !hyperlinkText) {
              this.errorText = "Both fields are required.";
              return false;
            }
            const urlValid = !isURLInvalid(hyperlinkUrl);
            if (urlValid) {
              this.errorText = null;
              return true;
            } else {
              this.errorText = "Please enter a valid url."
              return false;
            }
        },
    }
}
</script>
<style scoped lang="scss">
.error-container {
  position: relative;
  top: -24px;
  font-size: 12px;
  color: rgb(230, 87, 34);
  width: 100%;
}
</style>